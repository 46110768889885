import "./Footer.css";

const Footer = () => {
	return (
		<footer id="footer">
			<p>Desenvolvido por &copy; Julio Nabor</p>
		</footer>
	);
};

export default Footer;
